import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getClientIP, visitorCount, visitor_Counting } from '../service/risk.service';
import useStore from '../lib/store/useStore';

const useClientInfo = () => {
  const { setUuid, setClientIP, selectedDateRange } = useStore((state) => ({
    setUuid: state.setUuid,
    setClientIP: state.setClientIP,
    selectedDateRange: state.selectedDateRange,
  }));

  const [visitorData, setVisitorData] = useState({
    today: 0,
    last7Days: 0,
    last1Month: 0,
  });

  useEffect(() => {
    const initClientInfo = async () => {
      // UUID 설정
      let storedUuid = localStorage.getItem('user-uuid');

      if (!storedUuid) {
        storedUuid = uuidv4();
        localStorage.setItem('user-uuid', storedUuid);
      }

      setUuid(storedUuid);

      // 클라이언트 IP 설정
      let clientIP = '';
      try {
        const response = await getClientIP();
        clientIP = response.ip;
        setClientIP(clientIP);
      } catch (error) {
        console.error('Failed to fetch client IP:', error);
      }

      // 방문자 정보 전송 및 카운팅
      try {
        if (storedUuid && clientIP && selectedDateRange[0]) {
          await visitorCount(clientIP, storedUuid, selectedDateRange[0]);

          // 방문자 수 가져오기
          const counts = await visitor_Counting();

          // JSON 데이터를 state에 저장
          const visitorCounts = {
            today: counts['Today'] || 0,
            last7Days: counts['Last 7 Days'] || 0,
            last1Month: counts['Last 1 Month'] || 0,
          };
          
          setVisitorData(visitorCounts);

          // 방문자 수를 로그에 출력
          console.log('Visitor Counts:', visitorCounts);

        }
      } catch (error) {
        console.error('Failed to count visitors:', error);
      }
    };

    initClientInfo();
  }, [selectedDateRange, setClientIP, setUuid]);

  return visitorData;
};

export default useClientInfo;
