import React, { useState, useEffect } from 'react';
import { getWipInfo } from '../service/risk.service';
import useStore from '../lib/store/useStore';
import axios from 'axios';
import LoadingIndicator from './LoadingIndicator';
import LLMSelectBox from './LLMSelectBox';

function CeoLlmBox() {
    const [wipInfo, setWipInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { com_name, selectedDateRange, dateRange, selectedCategory, reportType } = useStore(state => ({
        com_name: state.com_name,
        selectedDateRange: state.selectedDateRange,
        dateRange: state.dateRange,
        selectedCategory: state.selectedCategory,
        reportType : state.reportType
    }));

    useEffect(() => {
        let isMounted = true;
        const source = axios.CancelToken.source();

        const fetchWipInfo = async () => {
            try {
                const data = await getWipInfo(com_name, selectedDateRange[0], dateRange, selectedCategory,reportType, source.token);
                if (isMounted) {
                    setWipInfo(Array.isArray(data) ? data : []);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error('Error fetching WIP info:', error);
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        setIsLoading(true);
        if (com_name && selectedDateRange[0] && dateRange) {
            fetchWipInfo();
        }

        return () => {
            isMounted = false;
            source.cancel('컴포넌트가 언마운트되어 요청이 취소되었습니다.');
        };
    }, [com_name, selectedDateRange, dateRange, selectedCategory, reportType]);

    const truncateText = (text, maxLength) => {
        return text;
    };

    return (
        <div>
            <div className="flex items-center mx-auto text-base font-medium pt-2 p-3 whitespace-nowrap">
                <span className='pr-1 no-select'>LLM 분석</span> 
                <span className="tooltip">
                    <i className="fa-regular fa-circle-question text-gray-500 pr-1"></i>
                    <span className="tooltip-text">기업에 대한 상황 진단 및<br/>기업 대응 전략을 알려줍니다.</span>
                </span>
                <LLMSelectBox />
            </div>
            <div className="wip-info-container p-4 h-96 text-sm custom:text-base font-medium ">
                {isLoading ? (
                    <div className="flex justify-center items-center pt-60">
                        <LoadingIndicator />
                    </div>
                ) : wipInfo.length > 0 ? (
                    wipInfo.map((info, index) => (
                        <div key={index} className="info-item mb-1">
                            <p>{truncateText(info, 70)}</p>
                        </div>
                    ))
                ) : (
                    <p>데이터가 없습니다. 추가결제를 해주세요.</p>
                )}
            </div>
        </div>
    );
}

export default CeoLlmBox;
