import React, { useEffect, useState } from 'react';
import useStore from '../lib/store/useStore';
import { getFetch_insights } from '../service/risk.service';
import LoadingIndicator from './LoadingIndicator';

const CeoCartegorys = () => {
    const { com_name, selectedDateRange, dateRange, selectedCategory } = useStore(state => ({
        com_name: state.com_name,
        selectedDateRange: state.selectedDateRange,
        dateRange: state.dateRange,
        selectedCategory: state.selectedCategory
    }));

    const [insights, setInsights] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await getFetch_insights(com_name, selectedDateRange[0], dateRange, selectedCategory);
                setInsights(Array.isArray(data) ? data : []); 
            } catch (error) {
                console.error("데이터 가져오기 실패:", error);
                setInsights([]); // 배열 아닐때 빈배열로 일단 예외처리해버림
            } finally {
                setLoading(false);
            }
        };
        

        if (com_name && selectedDateRange && dateRange && selectedCategory) {
            fetchData();
        }
    }, [com_name, selectedDateRange, dateRange, selectedCategory]);

    return (
        <div className='h-80'>
            <p className='mx-auto text-base font-medium pt-1.5 pl-2 pb-2'>
                <span className='pr-2 no-select'>Insight</span>
                <span className="tooltip">
                    <i className="fa-regular fa-circle-question text-gray-500"></i>
                    <span className="tooltip-text">해당 기간 내 중요한 <br/>인사이트를 제공합니다.</span>
                </span>
            </p>

            <div className="insights-container pl-2 h-80 text-sm custom:text-base font-medium pt-2">
                {loading ? (
                    <LoadingIndicator />
                ) : (
                    insights.map((item, index) => (
                        <p key={index} className="insight-item leading-tight">
                            {item}
                        </p>
                    ))
                )}
            </div>
        </div>
    );
};

export default CeoCartegorys;
