import React from 'react';
import { ClipLoader } from 'react-spinners'; // react-spinners의 ClipLoader 임포트

const LoadingIndicator = () => (
  <div className="flex justify-center items-center h-full">
    <ClipLoader color="#36D7B7" size={48} /> {/* ClipLoader를 사용하여 로딩 스피너를 구현 */}
    <p className="ml-4 text-white">Loading...</p>
  </div>
);

export default LoadingIndicator;
