const useDayClassName = (dateRange, startDate) => {
  const getDayClassName = (date) => {
    if (!startDate) return undefined;

    // 선택한 날짜를 강조하기 위한 클래스 추가
    if (date.toDateString() === startDate.toDateString()) {
      return 'highlight-selected';
    }

    const today = new Date();
    
    // 주간 하이라이트 조건
    if (dateRange === 'weekly') {
      const startOfWeek = new Date(startDate);
      startOfWeek.setDate(startDate.getDate() - 4);

      let endOfWeek = new Date(startDate);
      endOfWeek.setDate(startDate.getDate() + 3);

      // 미래 날짜가 포함되면 그만큼 과거로 이동
      if (endOfWeek > today) {
        const daysOver = Math.ceil((endOfWeek - today) / (1000 * 60 * 60 * 24));
        endOfWeek.setDate(endOfWeek.getDate() - daysOver);
        startOfWeek.setDate(startOfWeek.getDate() - daysOver);
      }

      if (date >= startOfWeek && date <= endOfWeek) return 'highlight-week';
    } 
    // 월간 하이라이트 조건
    else if (dateRange === 'monthly') {
      const startOfMonthRange = new Date(startDate);
      startOfMonthRange.setDate(startDate.getDate() - 16);

      let endOfMonthRange = new Date(startDate);
      endOfMonthRange.setDate(startDate.getDate() + 15);

      // 미래 날짜가 포함되면 그만큼 과거로 이동
      if (endOfMonthRange > today) {
        const daysOver = Math.ceil((endOfMonthRange - today) / (1000 * 60 * 60 * 24));
        endOfMonthRange.setDate(endOfMonthRange.getDate() - daysOver);
        startOfMonthRange.setDate(startOfMonthRange.getDate() - daysOver);
      }

      if (date >= startOfMonthRange && date <= endOfMonthRange) return 'highlight-month';
    }

    return undefined;
  };

  return getDayClassName;
};

export default useDayClassName;
