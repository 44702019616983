// src/lib/store/useStore.js
import { create } from 'zustand'; // 명시적 내보내기 사용

const useStore = create((set) => ({
  com_name: 'SK', // 기본값을 'SK'로 설정
  setCom_Name: (name) => {
    set({ com_name: name || 'SK' });
  },

  uuid: '', 
  setUuid: (uuid) => {
    set({ uuid: uuid });
  },

  clientIP: '', 
  setClientIP: (clientIP) => {
    set({ clientIP: clientIP });
  },

  comp_com: '',
  setComp_Com: (com) => set({ comp_com: com }),

  dateRange : 'monthly',
  setDateRange: (range) => set({ dateRange: range }),

  selectedDateRange: [new Date(), null],
  setSelectedDateRange: (range) => set({ selectedDateRange: range }),

  selectedCategory: '대관', // 초기 값 설정
  setSelectedCategory: (category) => {
    set({ selectedCategory: category });
  },

  reportType : '동향 보고서',
  setReportType: (reportType) => set({ reportType: reportType }),

  
}));
  



export default useStore;
