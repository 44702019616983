import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../custom-datepicker.css';
import { ko } from 'date-fns/locale';
import CeoCmpArticle from './CeoCmpArticle';
import CeoArticle from './CeoArticle';
import CeoRiskChart from './CeoRiskChart';
import CompanySelector from './CompanySelector';
import useStore from '../lib/store/useStore';
import CeoRiskRank from './CeoRiskRank';
import CeoSummary from './CeoSummary';   
import CeoLlmBox from './CeoLlmBox';
import DropdownMenu from './DropdownMenu';
import CompactVisitorStats from './CompactVisitorStats';
import FeedbackModal from './FeedbackModal';
import KeywordCloud from './KeywordCloud';
import useClientInfo from '../hooks/useClientInfo';
import CeoCartegorys from './CeoCartegorys';
import useDayClassName from '../hooks/useDayClassName';

const Box = ({ className = '', children }) => (
  <div className={`bg-gray-800 rounded-md border border-gray-700 ${className} overflow-visible`}>
    {children}
  </div>
);

const DashBoard = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const {dateRange, setDateRange, selectedDateRange, setSelectedDateRange  } = useStore((state) => ({
    dateRange: state.dateRange,
    setDateRange: state.setDateRange,
    selectedDateRange: state.selectedDateRange,
    setSelectedDateRange: state.setSelectedDateRange
  }));

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const visitorData = useClientInfo();

  const handlePeriodChange = (newPeriod) => {
    setDateRange(newPeriod);  
  };

  const getDayClassName = useDayClassName(dateRange, selectedDateRange[0]);

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 p-4">
      <div className="grid grid-cols-12 gap-1.5">
        {/* 왼쪽 섹션 */}
        <div className="col-span-5 flex flex-col">
          <div className="h-10 w-full flex items-center justify-left px-2">
            <CompanySelector className="col-span-8 mb-4 col" />&nbsp;
            <span className="mr-0 border border-gray-600 rounded-md px-2 py-1 text-blue-500 whitespace-nowrap shadow-lg bg-gradient-to-r from-gray-700 to-gray-900">날짜</span>
            <div className="relative w-40">
              <DatePicker
                selected={selectedDateRange[0]}
                selectsRange={true}
                onChange={(range) => setSelectedDateRange(range)}
                startDate={selectedDateRange[0]}
                endDate={selectedDateRange[1]}
                dateFormat="yyyy년 MM월 dd일"
                className="bg-gray-800 text-gray-100 border border-gray-600 rounded-md px-2 py-1 outline-none focus:border-blue-500 w-full text-center pr-6"
                calendarClassName="custom-datepicker"
                locale={ko}
                maxDate={new Date()} // 현재 날짜까지 선택 가능
                dayClassName={getDayClassName} // 조건부 하이라이트 적용
                dateFormatCalendar="yyyy MMMM"
              />
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              </div>
            </div>
            <div className="flex space-x-2 ml-4">
              <button
                onClick={() => handlePeriodChange('daily')}
                className={`px-3 py-1 rounded-md border whitespace-nowrap ${dateRange === 'daily' ? 'text-blue-500 border-blue-500' : 'text-gray-100 border-gray-600'} bg-gradient-to-r from-gray-700 to-gray-900`}
              >
                일간
              </button>
              <button
                onClick={() => handlePeriodChange('weekly')}
                className={`px-3 py-1 rounded-md border whitespace-nowrap ${dateRange === 'weekly' ? 'text-blue-500 border-blue-500' : 'text-gray-100 border-gray-600'} bg-gradient-to-r from-gray-700 to-gray-900`}
              >
                주간
              </button>
              <button
                onClick={() => handlePeriodChange('monthly')}
                className={`px-3 py-1 rounded-md border whitespace-nowrap ${dateRange === 'monthly' ? 'text-blue-500 border-blue-500' : 'text-gray-100 border-gray-600'} bg-gradient-to-r from-gray-700 to-gray-900`}
              >
                월간
              </button>
            </div>
            &nbsp;&nbsp;<DropdownMenu className="flex-grow" />&nbsp;&nbsp;  
          </div>  
        </div>

        {/* 중앙 섹션 */}
        <div className='flex items-center justify-center col-span-4 pl-20 '>
          <p className="text-lg custom:text-xl font-bold futuristic-font">
            Powered by Reputation TechX
          </p>
        </div>

        {/* 방문자 통계 */}
        <div className="col-span-2 grid grid-cols-1 gap-2 items-center">
          <CompactVisitorStats
            dailyVisitors={visitorData.today} // 오늘의 방문자 수
            weeklyVisitors={visitorData.last7Days} // 지난 7일간의 방문자 수
            monthlyVisitors={visitorData.last1Month} // 지난 1개월간의 방문자 수
          />
        </div>

        {/* 문의하기 버튼 */}
        <div className="w-full flex">
          <button
            onClick={openModal}
            className="bg-gray-800 text-gray-100 border border-gray-600 rounded-md px-3 py-1 shadow-lg bg-gradient-to-r from-gray-700 to-gray-900 whitespace-nowrap hover:text-blue-500"
          >
            문의하기
          </button>
          <FeedbackModal isOpen={isModalOpen} onRequestClose={closeModal} />
        </div>

        {/* 주요 컴포넌트 그리드 */}
        <div className="col-span-12 grid grid-cols-8 gap-1.5">
          {/* 리스크 랭크 */}
          <Box className="col-span-1 row-span-2 max-full max-w-full">
            <CeoRiskRank />
          </Box>

          {/* 리스크 차트 */}
          <Box className="col-span-4 row-span-2 h-full w-full">
            <CeoRiskChart />
          </Box>

          {/* 요약 및 기타 컴포넌트 */}
          <div className="col-span-3 row-span-4 gap-1.5">
            <Box className='col-span-3 h-20 mb-1.5'>
              <CeoSummary />
            </Box>
            <div className='grid grid-cols-4 gap-1.5'>
              <Box className='col-span-2 h-full'>
                <CeoCartegorys />
              </Box>
              <Box className='col-span-2 row-span-2 h-full'>
                <CeoLlmBox />
              </Box>
              <Box className='col-span-2 h-80 max-h-full'>
                 <KeywordCloud />
              </Box>
            </div>
          </div>

          {/* 유사 기업 기사 */}
          <Box className="col-span-5 row-span-2 h-full">
            <CeoCmpArticle />
          </Box>
        </div>

        {/* 전체 기업 기사 */}
        <Box className="col-span-12 h-auto">
           <CeoArticle />
        </Box>
      </div>
    </div>
  );
};

export default DashBoard;
