import React, { useState, useEffect } from 'react';
import { getSummaryInfo } from '../service/risk.service';  
import useStore from '../lib/store/useStore'; 
import LoadingIndicator from './LoadingIndicator';

function CeoSummary() {
    const [filteredSummaries, setFilteredSummaries] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    const { com_name, selectedDateRange, dateRange } = useStore(state => ({
        com_name: state.com_name,
        selectedDateRange: state.selectedDateRange,
        dateRange: state.dateRange,
    }));

    useEffect(() => {
        let isMounted = true; // 컴포넌트가 마운트된 상태를 추적하기 위한 플래그
        let intervalId; // setInterval ID

        const fetchSummaries = async () => {
            setLoading(true);
            try {
                console.log('API 호출 시작');
                const summaries = await getSummaryInfo(com_name, selectedDateRange[0], dateRange);
                console.log('API 응답 데이터:', summaries);

                if (isMounted) {
                    let validSummaries = [];

                    if (Array.isArray(summaries)) {
                        // 배열이면 기존 방식대로 처리
                        validSummaries = summaries
                            .filter(item => typeof item === 'string' && item.trim() !== '')
                            .map(item => item.trim());
                    } else if (typeof summaries === 'string') {
                        // 단일 문자열이면 배열로 변환
                        validSummaries = [summaries.trim()];
                    }

                    console.log('유효한 요약:', validSummaries);
                    setFilteredSummaries(validSummaries);

                    // 유효한 요약이 있을 경우 5초마다 요약 변경
                    if (validSummaries.length > 0) {
                        intervalId = setInterval(() => {
                            setCurrentIndex(prevIndex => (prevIndex + 1) % validSummaries.length);
                        }, 5000);
                    } else {
                        setFilteredSummaries([]);
                    }
                }
            } catch (error) {
                console.error('API 호출 실패:', error);
                if (isMounted) setFilteredSummaries([]);  // 에러 발생 시 빈 배열 설정
            } finally {
                if (isMounted) setLoading(false); // 로딩 상태 해제
            }
        };

        if (com_name && selectedDateRange[0]) {
            fetchSummaries();
        } else {
            console.log('com_name 또는 selectedDateRange가 유효하지 않습니다');
            setLoading(false);
        }

        return () => {
            isMounted = false;
            clearInterval(intervalId); // 컴포넌트가 언마운트될 때 interval 해제
        };
    }, [com_name, selectedDateRange, dateRange]); // selectedDateRange를 의존성 배열에 포함

    return (
        <div>
            <p className='mx-auto text-base font-medium pt-1 pl-2'>
                <span className='pr-2 no-select'>Summarize article</span> 
                <span className="tooltip">
                    <i className="fa-regular fa-circle-question text-gray-500"></i>
                    <span className="tooltip-text">해당 기간 내 기업 총수<br/> 관련 뉴스를 요약해줍니다.</span>
                </span>
            </p>

            <div className="wip-info-container pl-3 h-auto text-sm custom:text-base font-medium ">
                {loading ? (
                    <div className="flex justify-center items-center">
                        <LoadingIndicator/>
                    </div>
                ) : filteredSummaries.length > 0 ? (
                    <div className="text-sm custom:text-base text-green-500 pt-2">
                        <div className="pl-2 break-words whitespace-nowrap overflow-hidden text-ellipsis">
                            {filteredSummaries[currentIndex]}
                        </div>
                    </div>
                ) : (
                    <p className="text-sm custom:text-base text-green-500 ">토큰이 없습니다. 추가결제를 해주세요.</p>
                )}
            </div>
        </div>
    );
}

export default CeoSummary;
