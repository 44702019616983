import React, { useEffect, useState } from 'react';
import useStore from '../lib/store/useStore';
import { getAvg, nowRiskScore } from '../service/risk.service';

const CeoScoreBar = () => {
  const [score, setScore] = useState(null);
  const [avg, setAvg] = useState(null);
  const [loading, setLoading] = useState(true);

  const { com_name, selectedDateRange } = useStore(state => ({
    com_name: state.com_name,
    selectedDateRange: state.selectedDateRange,
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const date = selectedDateRange[0];

        // Promise.allSettled로 병렬로 두 API 호출
        const [scoreResult, avgResult] = await Promise.allSettled([
          nowRiskScore(com_name, date),
          getAvg(com_name, date),
        ]);

        // 점수 처리
        if (scoreResult.status === 'fulfilled') {
          const fetchedScore = typeof scoreResult.value === 'number'
            ? scoreResult.value
            : parseFloat(scoreResult.value);
          setScore(fetchedScore);
        } else {
          console.error('Score API 호출 실패:', scoreResult.reason);
        }

        // 평균 처리
        if (avgResult.status === 'fulfilled') {
          setAvg(avgResult.value);
        } else {
          console.error('Average API 호출 실패:', avgResult.reason);
        }
      } catch (error) {
        console.error('데이터 가져오기 오류:', error);
      } finally {
        setLoading(false);
      }
    };

    if (com_name && selectedDateRange[0]) {
      fetchData();
    }
  }, [com_name, selectedDateRange]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-gray-800 text-gray-100 p-4 rounded-md shadow-sm h-10 w-full">
      <div className="flex justify-center items-center h-full text-sm">
        <div className="flex items-center whitespace-nowrap">
          <span className="font-semibold mr-2 text-sm custom:text-base no-select ">Score : </span>
          <span className='font-semibold mr-2 text-green-600 text-sm custom:text-base'>{score || 0}</span>
          <span className="tooltip pr-2">
            <i className="fa-regular fa-circle-question text-gray-500"></i> 
            <span className="tooltip-text">현 시점에서 기업 총수의<br/>평판 점수입니다.</span>
          </span>
        </div>
        <div className="flex items-center whitespace-nowrap pr-2">
          <span className="font-semibold mr-2 text-sm custom:text-base pl-1">Score Avg : </span>
          <span className='font-semibold text-green-600 text-sm custom:text-base'>{avg || 0}</span>
          <span className="tooltip pl-2">
            <i className="fa-regular fa-circle-question text-gray-500"></i>
            <span className="tooltip-text">해당 기간에서 기업 총수의<br/> 평균 평판 점수입니다.</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CeoScoreBar;
