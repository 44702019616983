import React, { useEffect, useState } from 'react';
import { getArticleList } from '../service/risk.service';
import useStore from '../lib/store/useStore';
import LoadingIndicator from './LoadingIndicator';
import axios from 'axios';

const Row = ({ index, style, data }) => {
  const item = data[index];
  return (
    <tr key={item.id || item.article_link || `${item.ceo_name}-${index}`} style={style}>
      <td className="py-2 px-4 border-b border-gray-700 truncate max-w-xs">{item.ceo_name}</td>
      <td className="py-2 px-4 border-b border-gray-700">{item.pos_neg}</td>
      <td className="py-2 px-4 border-b border-gray-700 truncate max-w-xs">
        <a 
          href={item.article_link || '#'}
          className="text-blue-500 hover:text-blue-700 truncate" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          {item.article_title || ''}
        </a>
      </td>
      <td className="py-2 px-4 border-b border-gray-700 truncate max-w-xs">{item.article_contents || ''}</td>
    </tr>
  );
};

const CeoArticle = () => {
  // 개별 상태 추출
  const com_name = useStore(state => state.com_name);
  const selectedDateRange = useStore(state => state.selectedDateRange);
  const dateRange = useStore(state => state.dateRange);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const start_date = selectedDateRange?.[0];

  useEffect(() => {
    if (!com_name || !start_date || !dateRange) {
      setData([]);
      setLoading(false);
      return;
    }

    const controller = new AbortController(); // AbortController 인스턴스 생성
    const { signal } = controller; // signal 추출

    const fetchData = async () => {
      console.log('Fetching data...');
      try {
        setLoading(true);
        const response = await getArticleList(com_name, start_date, dateRange, signal);
        console.log('API Response:', response);
        if (response?.Message === 'Articles Data Not Found') {
          setData([]);
        } else {
          setData(Array.isArray(response) ? response : [response]);
        }
        setError(null);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('요청이 취소되었습니다:', error.message);
        } else {
          setError(error);
          console.log('데이터 패칭 오류:', error);
        }
      } finally {
        setLoading(false);
        console.log('Fetching completed.');
      }
    };

    fetchData();

    return () => {
      controller.abort(); // 컴포넌트 언마운트 시 요청 취소
    };
  }, [com_name, start_date, dateRange]);

  if (loading) return <LoadingIndicator />;
  if (error) return <div className="text-red-500">Error: {error?.message || '알 수 없는 오류가 발생했습니다.'}</div>;

  return (
    <>
      <p className='mx-auto text-base font-medium pt-2 p-3'>
        <span className='pr-2 no-select'>관련 기사</span> 
        <span className="tooltip">
          <i className="fa-regular fa-circle-question text-gray-500"></i>
          <span className="tooltip-text">기업 총수와 관련된<br /> 뉴스를 실시간으로 보여줍니다.</span>
        </span>
      </p>
      <div className="max-h-60 overflow-y-scroll border border-gray-700 rounded-lg">
        <table className="min-w-full bg-gray-800 border border-gray-700 border-t-0 border-b-0">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-700 text-left w-1/10">CEO명</th>
              <th className="py-2 px-4 border-b border-gray-700 text-left w-1/10">긍정/부정</th>
              <th className="py-2 px-4 border-b border-gray-700 text-left w-3/10">기사 제목</th>
              <th className="py-2 px-4 border-b border-gray-700 text-left w-5/10">기사 내용</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan="4" className="py-2 px-4 border-b border-gray-700 text-center">조회된 기사가 없습니다.</td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-b border-gray-700 truncate max-w-xs">{item.ceo_name || 'Unknown'}</td>
                  <td className="py-2 px-4 border-b border-gray-700">{item.pos_neg}</td>
                  <td className="py-2 px-4 border-b border-gray-700 truncate max-w-xs">
                    <a 
                      href={item.article_link || '#'}
                      className="text-blue-500 hover:text-blue-700 truncate" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      {item.article_title || 'No Title'}
                    </a>
                  </td>
                  <td className="py-2 px-4 border-b border-gray-700 truncate max-w-xs">{item.article_contents || 'No Content'}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CeoArticle;
