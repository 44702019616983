// src/components/CompanySelector.jsx

import React from 'react';
import PropTypes from 'prop-types';

const ChartCompanySelector = ({ cmpCompany, setCmpCompany }) => (
  <>
    <select
      className="bg-gray-800 text-gray-100 border border-gray-600 rounded-md px-2 py-1 outline-none focus:border-blue-500 w-auto text-center"
      value={cmpCompany}
      onChange={(e) => setCmpCompany(e.target.value)}
    >
      <option value="">선택</option>
      <option value="SK">SK</option>
      <option value="삼성전자">삼성전자</option>
      <option value="현대자동차">현대자동차</option>
      <option value="LG">LG</option>
      <option value="네이버">네이버</option>
      <option value="카카오">카카오</option>
      <option value="포스코">포스코</option>
      <option value="한진칼">한진칼</option>
      <option value="하이브">하이브</option>
      <option value="SPC">SPC</option>
    </select>
    <span className="tooltip">
      <i className="fa-regular fa-circle-question text-gray-500"></i>
      <span className="tooltip-text">비교 대상 기업을<br/> 선택할 수 있습니다.</span>
    </span>
  </>
  
);

ChartCompanySelector.propTypes = {
  cmpCompany: PropTypes.string.isRequired,
  setCmpCompany: PropTypes.func.isRequired,
};

export default ChartCompanySelector;
