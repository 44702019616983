import React, { useEffect, useState, useRef } from 'react';
import { TagCloud } from 'react-tagcloud';
import useStore from '../lib/store/useStore';
import { getKeywordCloudData } from '../service/risk.service';
import { Tooltip } from 'react-tooltip';
import LoadingIndicator from './LoadingIndicator'; // 로딩 스피너 컴포넌트 임포트


const KeywordCloud = () => {
  const { com_name, selectedDateRange } = useStore((state) => ({
    com_name: state.com_name,
    selectedDateRange: state.selectedDateRange,
  }));

  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const fetchKeywordData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await getKeywordCloudData(com_name, selectedDateRange[0]);
        if (response && Array.isArray(response)) {
          const tagsWithKeys = response.map((tag, index) => ({
            ...tag,
            key: index,
          }));
          setTags(tagsWithKeys);
        }
      } catch (error) {
        setError('Failed to fetch keyword cloud data');
      } finally {
        setLoading(false);
      }
    };

    if (com_name && selectedDateRange[0]) {
      fetchKeywordData();
    }
  }, [com_name, selectedDateRange]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const minSize = Math.max(containerWidth / 45, 13);
  const maxSize = Math.min(containerWidth / 10, 43);

  const levelColors = {
    1: '#FF6347', // 레벨 1: 빨간색
    2: '#4682B4', // 레벨 2: 파란색
    3: '#32CD32', // 레벨 3: 녹색
    4: '#FFD700', // 레벨 4: 노란색
  };

  const defaultColor = '#FFFFFF'; // 레벨이 없는 경우 기본 색상

  return (
    <div className='h-96'>
      <p className='mx-auto text-base font-medium pt-1 pl-2'>
        <span className='pr-2 no-select'>관련 주요 키워드</span> 
        <span className="tooltip">
            <i className="fa-regular fa-circle-question text-gray-500"></i>
            <span className="tooltip-text">기업 총수와 관련된<br/> 주요 키워드입니다.</span>
        </span>
      </p>
      <div  
        ref={containerRef}
        className="relative bg-gray-800 w-full h-72 mx-auto p-2 text-center overflow-y-auto pt-12" // overflow-y-auto 추가
      >
        {loading && <LoadingIndicator/>}
        {error && <p className="text-red-500">{error}</p>}
        {!loading && !error && (
          <>
            <TagCloud
              minSize={minSize}
              maxSize={maxSize}
              tags={tags}
              className="Keyword_Cloud"
              renderer={(tag, size, color) => (
                <span
                  key={tag.key}
                  style={{
                    fontSize: `${size}px`,
                    color: levelColors[tag.level] || defaultColor, // 레벨이 없을 경우 기본 색상 적용
                    margin: '1px',
                    padding: '1.5px',
                    cursor: 'pointer',
                    display: 'inline-block',
                  }}
                  data-tooltip-id={`tooltip-${tag.key}`}
                  data-tooltip-content={`${tag.value} : ${tag.count}`}
                >
                  {tag.value}
                </span>
              )}
            />
            {tags.map((tag) => (
              <Tooltip
                key={`tooltip-${tag.key}`}
                id={`tooltip-${tag.key}`}
                place="top"
                type="dark"
                effect="solid"
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default KeywordCloud;

