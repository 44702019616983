import React, { useEffect } from 'react';
import useStore from '../lib/store/useStore';

function LLMSelectBox() {
    const { selectedCategory, reportType, setReportType } = useStore((state) => ({
        selectedCategory: state.selectedCategory,
        reportType: state.reportType,
        setReportType: state.setReportType,
    }));

    const options = {
        "대관": { report_1: "동향 보고서", report_2: "대응 전략" },
        "홍보": { report_1: "동향 보고서", report_2: "해명문" },
        "투자": { report_1: "주주 지분율", report_2: "리스크 괴리도" }
    };

    useEffect(() => {
        setReportType('report_1');
    }, [selectedCategory, setReportType]);

    return (
        <div className="flex items-center space-x-1">
            <select
                className="bg-gray-800 text-gray-100 border border-gray-600 rounded-md py-1 outline-none focus:border-blue-500 text-center"
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
            >
                {options[selectedCategory] && Object.entries(options[selectedCategory]).map(([key, label]) => (
                    <option key={key} value={key}>
                        {label}
                    </option>
                ))}
            </select>
            <i className="fa-regular fa-circle-question text-gray-500 whitespace-nowrap tooltip">
                <span className="tooltip-text">
                    {Object.values(options[selectedCategory] || {}).join("와 ")}을<br />선택하여 확인할 수 있습니다.
                </span>
            </i>
        </div>
    );
}

export default LLMSelectBox;
