// src/components/CeoRiskChart.js
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, Filler } from 'chart.js';
import useCeoRiskChartData from '../hooks/useCeoRiskChartData';
import CeoScoreBar from './CeoScoreBar';
import LoadingIndicator from './LoadingIndicator';
import ErrorMessage from './ErrorMessage';
import ChartCompanySelector from './ChartCompanySelector';
import generateChartOptions from '../utils/chartOptions';
import useStore from '../lib/store/useStore';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, Filler);

const CeoRiskChart = () => {
  const { com_name, selectedDateRange, dateRange } = useStore(state => ({
    com_name: state.com_name,
    selectedDateRange: state.selectedDateRange,
    dateRange: state.dateRange,
  }));

  const [cmpCompany, setCmpCompany] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1540);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 1540);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formattedStartDate = selectedDateRange[0];
  const { chartData, loading, error, summaries, cmpSummaries } = useCeoRiskChartData(com_name, formattedStartDate, dateRange, cmpCompany);
  const options = generateChartOptions(dateRange, summaries, cmpSummaries, chartData, formattedStartDate, isSmallScreen);

  return (
    <>
      <div className="flex items-center justify-between mx-auto pl-3 pt-1 pr-2">
        <p className='text-base font-medium whitespace-nowrap'>
          <span className='pr-2 no-select'>Sentimental Score</span> 
          <span className="tooltip">
            <i className="fa-regular fa-circle-question text-gray-500"></i>
            <span className="tooltip-text">해당 기간 내 기업 총수의<br/> 평판 추세를 보여줍니다.</span>
          </span>
        </p>
        <div className="flex flex-nowrap items-center space-x-4">
          <CeoScoreBar />
          <ChartCompanySelector cmpCompany={cmpCompany} setCmpCompany={setCmpCompany} />
        </div>
      </div>
      <div className="relative w-full h-96 mx-auto">
        {loading ? (
          <LoadingIndicator />
        ) : error ? (
          <ErrorMessage message={error} />
        ) : (
          <Line data={chartData} options={options} />
        )}
      </div>
    </>
  );
};

export default CeoRiskChart;
