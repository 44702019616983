import React, { useEffect, useState } from 'react';
import { getCmpArticleList } from '../service/risk.service';
import useStore from '../lib/store/useStore';
import LoadingIndicator from './LoadingIndicator';

const CeoCmpArticle = () => {
  const { com_name, selectedDateRange } = useStore(state => ({
    com_name: state.com_name,
    selectedDateRange: state.selectedDateRange,
  }));

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [noArticles, setNoArticles] = useState(false);

  const start_date = selectedDateRange[0];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getCmpArticleList(com_name, start_date);
        if (response?.Message === 'Article Data Not Found') {
          setData([]);
          setNoArticles(true);
        } else {
          setData(Array.isArray(response) ? response : [response]);
          setNoArticles(false);
        }
        setError(null); 
      } catch (error) {
        setError(error);
        setNoArticles(false);
        console.log('데이터 패칭 오류:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [com_name, start_date]); 

  if (loading) return <LoadingIndicator />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <p className='mx-auto text-base font-medium pt-2 p-3'>
        <span className='pr-2 no-select'>유사 기업 사례</span> 
        <span className="tooltip">
            <i className="fa-regular fa-circle-question text-gray-500"></i>
            <span className="tooltip-text">다른 기업에 어떤 유사한<br/>사례가 있었는지 보여줍니다.</span>
        </span>
      </p>
      <div className="max-h-80 h-60 overflow-y-scroll border border-gray-700 rounded-lg">
        <table className="min-w-full bg-gray-800 border border-gray-700 border-t-0 border-b-0">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-700 text-left w-1/8 whitespace-nowrap">비교회사</th>
              <th className="py-2 px-4 border-b border-gray-700 text-left w-2/8 hidden sm:table-cell whitespace-nowrap">비교기사 날짜</th>
              <th className="py-2 px-4 border-b border-gray-700 text-left w-1/8 hidden md:table-cell whitespace-nowrap">리스크 종류</th> 
              <th className="py-2 px-4 border-b border-gray-700 text-left w-4/8">기사제목</th>
              <th className="py-2 px-4 border-b border-gray-700 text-left w-1/8 hidden lg:table-cell whitespace-nowrap">유사도</th>
            </tr>
          </thead>
          <tbody>
            {noArticles ? (
              <tr>  
                <td colSpan="5" className="py-2 px-4 border-b border-gray-700 text-center">
                  조회된 기사가 없습니다.
                </td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-b border-gray-700 text-[10px] sm:text-xs md:text-sm lg:text-base whitespace-nowrap">
                    {item.comparison_company_name || ''}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-700 hidden sm:table-cell text-[10px] sm:text-xs md:text-sm lg:text-base whitespace-nowrap">
                    {item.comparison_date || ''}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-700 hidden md:table-cell text-[10px] sm:text-xs md:text-sm lg:text-base whitespace-nowrap">
                    {item.article_cate || '-'}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-700 truncate max-w-xs text-[10px] sm:text-xs md:text-sm lg:text-base">
                    <a 
                      href={item.article_link || '#'}
                      className="text-blue-500 hover:text-blue-700 truncate" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      {item.article_title || ''}
                    </a>
                  </td>
                  <td className="py-2 px-4 border-b border-gray-700 truncate max-w-xs text-[10px] sm:text-xs md:text-sm lg:text-base">
                     {item.similarity != '-' ? item.similarity + '%' : '-' }
                  </td>
                </tr>
              ))  
            )}  
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CeoCmpArticle;
