// src/hooks/useCeoRiskChartData.js

import { useState, useEffect, useCallback } from 'react';
import { getChartData } from '../service/risk.service';

const useCeoRiskChartData = (com_name, selectedDateRange, dateRange, cmpCompany) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [summaries, setSummaries] = useState([]);
  const [cmpSummaries, setCmpSummaries] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await getChartData(com_name, selectedDateRange, dateRange);
      if (!response || response.length === 0) {
        throw new Error('No data available for the selected company and date.');
      }

      const sortedResponse = response.sort((a, b) => new Date(a.date) - new Date(b.date));

      // 일간 데이터를 "HH:MM" 형식으로 표시
      const labels = sortedResponse.map(item => {
        if (dateRange === 'daily') {
          const date = new Date(item.date);
          return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
        }
        return item.date; // 일간 외의 데이터는 전체 날짜 포맷 유지
      });

      const data = labels.map(label => {
        const item = sortedResponse.find(item => {
          if (dateRange === 'daily') {
            // 시간만 추출해 일간 레이블과 매칭
            const itemDate = new Date(item.date);
            const itemTime = `${String(itemDate.getHours()).padStart(2, '0')}:${String(itemDate.getMinutes()).padStart(2, '0')}`;
            return itemTime === label;
          }
          return item.date === label;
        });
        return item && item.score != null ? parseFloat(item.score.toFixed(2)) : null;
      });

      const summariesData = labels.map(label => {
        const item = sortedResponse.find(item => {
          if (dateRange === 'daily') {
            const itemDate = new Date(item.date);
            const itemTime = `${String(itemDate.getHours()).padStart(2, '0')}:${String(itemDate.getMinutes()).padStart(2, '0')}`;
            return itemTime === label;
          }
          return item.date === label;
        });
        return item ? item.summary : 'No summary available';
      });
      setSummaries(summariesData);

      let cmpData = [];
      let cmpSummariesData = [];

      if (cmpCompany) {
        const cmpResponse = await getChartData(cmpCompany, selectedDateRange, dateRange);
        if (cmpResponse && cmpResponse.length > 0) {
          const sortedCmpResponse = cmpResponse.sort((a, b) => new Date(a.date) - new Date(b.date));

          cmpData = labels.map(label => {
            const item = sortedCmpResponse.find(item => {
              if (dateRange === 'daily') {
                const itemDate = new Date(item.date);
                const itemTime = `${String(itemDate.getHours()).padStart(2, '0')}:${String(itemDate.getMinutes()).padStart(2, '0')}`;
                return itemTime === label;
              }
              return item.date === label;
            });
            return item && item.score != null ? parseFloat(item.score.toFixed(2)) : null;
          });

          cmpSummariesData = labels.map(label => {
            const item = sortedCmpResponse.find(item => {
              if (dateRange === 'daily') {
                const itemDate = new Date(item.date);
                const itemTime = `${String(itemDate.getHours()).padStart(2, '0')}:${String(itemDate.getMinutes()).padStart(2, '0')}`;
                return itemTime === label;
              }
              return item.date === label;
            });
            return item ? item.summary : 'No summary available';
          });
          setCmpSummaries(cmpSummariesData);
        }
      }

      setChartData({
        labels: labels,
        datasets: [
          {
            label: `${
              com_name === 'SK' ? 'SK 최태원 회장' :
              com_name === '삼성전자' ? '삼성전자 이재용 회장' :
              com_name === '현대자동차' ? '현대차 정의선 회장' :
              com_name === 'LG' ? 'LG 구광모 회장' :
              com_name === '네이버' ? '네이버 이해진 GIO' :
              com_name === '카카오' ? '카카오 김범수 의장' :
              com_name === '포스코' ? '포스코 장인화 회장' :
              com_name === '한진칼' ? '한진칼 조원태 회장' :
              com_name === '하이브' ? '하이브 방시혁 의장' :
              com_name === 'SPC' ? 'SPC 허영인 회장' :
              '알 수 없는 총수'
            }`,
            data: data,
            borderColor: 'rgba(72, 187, 120, 1)',
            backgroundColor: 'rgba(55, 150, 100, 0.5)',
            fill: false,
            spanGaps: true,
            tension: 0.5,
          },
          cmpCompany && {
            label: `${
              cmpCompany === 'SK' ? 'SK 최태원 회장' :
              cmpCompany === '삼성전자' ? '삼성전자 이재용 회장' :
              cmpCompany === '현대자동차' ? '현대차 정의선 회장' :
              cmpCompany === 'LG' ? 'LG 구광모 회장' :
              cmpCompany === '네이버' ? '네이버 이해진 GIO' :
              cmpCompany === '카카오' ? '카카오 김범수 의장' :
              cmpCompany === '포스코' ? '포스코 장인화 회장' :
              cmpCompany === '한진칼' ? '한진칼 조원태 회장' :
              cmpCompany === '하이브' ? '하이브 방시혁 의장' :
              cmpCompany === 'SPC' ? 'SPC 허영인 회장' :
              '알 수 없는 총수'
            }`,
            data: cmpData,
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            fill: false,
            spanGaps: true,
            tension: 0.5,
          }
        ].filter(Boolean)
      });

    } catch (err) {
      console.log('데이터 패칭 오류:', err);
      setError(err.message || '데이터 패칭 중 오류가 발생했습니다.');
      setChartData({
        labels: [],
        datasets: []
      });
    } finally {
      setLoading(false);
    }
  }, [com_name, selectedDateRange, dateRange, cmpCompany]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { chartData, loading, error, summaries, cmpSummaries };
};

export default useCeoRiskChartData;
