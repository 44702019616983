// src/components/ErrorMessage.jsx 에러부분 공용 으로뺸거임 .. 나뉜부분은 차트 뿐임

import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ message }) => (
  <div className="text-center py-4 text-red-500">
    {message}
  </div>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorMessage;
